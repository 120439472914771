import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Navigation = () => {
  const scrolledRef = React.useRef(false);

  React.useEffect(() => {
    const fixedNavbar = document.querySelector(".navbar-fixed-top");
    const navList = document.querySelectorAll("ul.nav li");
    const logo = document.querySelectorAll("ul.nav li.logo");

    const onScroll = () => {
      if (window.pageYOffset > 150) {
        if (scrolledRef.current === true) {
          return;
        }

        scrolledRef.current = true;

        fixedNavbar.classList.add("shrink");

        navList.forEach((item) => {
          item.classList.add("is-scrolled");
        });

        logo.forEach((item) => {
          item.classList.remove("hidden");
          item.classList.add("is-scrolled");
        });
      }

      if (window.pageYOffset < 150) {
        if (scrolledRef.current === false) {
          return;
        }

        scrolledRef.current = false;
        fixedNavbar.classList.remove("shrink");

        navList.forEach((item) => {
          item.classList.remove("is-scrolled");
        });

        logo.forEach((item) => {
          item.classList.add("hidden");
          item.classList.remove("is-scrolled");
        });
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", onScroll);
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const scrollToElement = (id: string) => {
    document.querySelector(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <nav
      id="nav-bar"
      className="navbar navbar-default navbar-fixed-top"
      role="navigation"
    >
      <div className="container">
        <div className="brand-container">
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="header-logo.svg" />
          </a>
        </div>
        {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
        <ul className="nav navbar-nav">
          {/* <!-- Hidden li included to remove active class from about link when scrolled up past about section --> */}
          <li className="hidden logo">
            <a
              style={{ marginTop: 5 }}
              className="page-scroll"
              onClick={() => scrollToElement("#page-top")}
            >
              <img src="nav-logo.svg" />
            </a>
          </li>
          <li>
            <a
              style={{ marginBottom: 5 }}
              className="page-scroll"
              onClick={() => scrollToElement("#service")}
            >
              <img src="nav-service.svg" />
            </a>
          </li>
          <li>
            <a
              style={{ marginBottom: 5 }}
              className="page-scroll"
              onClick={() => scrollToElement("#sortiment")}
            >
              <img src="nav-sortiment.svg" />
            </a>
          </li>
          <li>
            <a
              style={{ marginBottom: 5 }}
              className="page-scroll"
              onClick={() => scrollToElement("#contact")}
            >
              <img src="nav-kontakt.svg" />
            </a>
          </li>
        </ul>
      </div>
      {/* <!-- /.navbar-collapse --> */}
      {/* <!-- /.container --> */}
    </nav>
  );
};
