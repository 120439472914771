import React from "react";

export const SortimentSection = () => {
  return (
    <section id="sortiment" className="sortiment-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sortiment-h">
              <img src="h-sortiment.svg" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <img className="tische-img" src="tisch.svg" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5 col-lg-5">
            <img
              className="theke-img"
              style={{ marginTop: 50 }}
              src="theke.svg"
            />
          </div>
          <div className="col-md-4 col-lg-4 sortiment-text">
            <p>Wählen Sie aus unserem großen Sortiment.</p>
            <br />
            <p>
              Neben diversen Wässern, Säften und Erfrischungsgetränken verfügen
              wir über alle gängigen Biersorten im Kasten oder Fass.
              <br />
            </p>
            <br />
            <p>
              Außerdem im Verleih: Verkaufswagen, Kühlhänger, Stehtische,
              Zeltgarnituren, Zapfanlagen, Spül- und Beistelltheken, Kühltruhen,
              Kühlschränke, Sonnenschirme, Willibecher, Wein- Sekt und
              Schnapsgläser.
              <br />
            </p>
          </div>
          <div className="col-md-2 col-lg-2"></div>
        </div>
      </div>
    </section>
  );
};
