import React from "react";

export const ContactSection = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="kontakt-h">
              <img src="h-kontakt.svg" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <img src="kontakt.svg" />
            <a href="mailto:sommereygmbh@web.de" className="email-overlay"></a>
          </div>
        </div>
      </div>
    </section>
  );
};
