import React from "react";
import ReactModal from "react-modal";
import { ImpressumModal } from "../partials/ImpressumModal";

ReactModal.setAppElement("#___gatsby");

export const Footer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <section id="footer" className="footer-section">
        <div
          role="button"
          onClick={() => setIsOpen(true)}
          data-toggle="modal"
          data-target="#impressumsModal"
        >
          Impressum
        </div>
      </section>
      <ReactModal isOpen={isOpen}>
        <ImpressumModal onClose={() => setIsOpen(false)} />
      </ReactModal>
    </>
  );
};
