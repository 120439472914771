import React from "react";

export const ServicesSection = () => {
  return (
    <section id="service" className="service-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="service-h">
              <img src="h-service.svg" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <img className="lkw-img" src="wagen-1.svg" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4 service-text">
            <p>
              Schon in der dritten Generation versorgen wir das Ruhrgebiet und
              Umgebung mit Getränken. Seit 1952 sind wir für Gastronomie- und
              Privatkunden im Einsatz.
            </p>
            <br />
            <p>
              Hochzeit? Geburtstag oder Gartenfete? Greifen Sie auf unsere
              langjährige Erfahrung zurück. Wir helfen Ihnen gerne bei der
              Planung und Umsetzung Ihrer individuellen Feier. Wir beraten Sie
              gerne persönlich vor Ort, helfen Ihnen am Telefon weiter oder
              schreiben Sie uns einfach eine Mail mit Ihren Wünschen und
              Vorstellungen.
              <br />
            </p>
          </div>
          <div className="col-md-5">
            <img className="bierwagen-img" src="wagen-2.svg" />
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </section>
  );
};
