import * as React from "react";
import { Navigation } from "../components/Navigation";
import { ServicesSection } from "../partials/ServicesSection";
import { Layout } from "../layout";
import { SortimentSection } from "../partials/SortimentSection";
import { ContactSection } from "../partials/ContactSection";
import { SwiperComponent } from "../components/Swiper";
import { Helmet } from "react-helmet";
import { Footer } from "../components/Footer";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: "de",
        }}
      >
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#FFFFFF" />
        <meta name="author" content="Sommerey GmbH" />
        <title>
          Getränke-Sommerey - Getränke Fachgrosshandel GmbH aus Bottrop
        </title>
        <meta
          name="keywords"
          content="getränke, bottrop, sommerey, fachgrosshandel, biertheke, zapfanlage, zeltgarnituren, verkaufswagen, getränkemarkt"
        />
        <meta
          name="description"
          content="Getraenke Fachgrosshandel Sommerey in Bottrop, Mozartstraße 16 ↪ Jetzt kostenlos anfragen: ☎ 02041 96432 ✉️ sommereygmbh@web.de"
        />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.png" />
        <meta name="robots" content="noodp" />
        <link rel="canonical" href="http://getraenke-sommerey.de/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Getraenke-Sommerey - Getraenke Fachgrosshandel GmbH"
        />
        <meta
          property="og:description"
          content="Getraenke Fachgrosshandel Sommerey in Bottrop, Mozartstraße 16 ↪ Jetzt kostenlos anfragen: ☎ 02051 96432 ✉️ sommereygmbh@web.de"
        />
        <meta property="og:url" content="http://getraenke-sommerey.de/" />
        <meta property="og:site_name" content="Getraenke-Sommerey" />
      </Helmet>

      <main>
        <div id="page-top" />

        <title>
          Getränke-Sommerey - Getränke Fachgrosshandel GmbH aus Bottrop
        </title>
        <Navigation />
        <SwiperComponent />
        <ServicesSection />
        <SortimentSection />
        <ContactSection />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
