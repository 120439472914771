import * as React from "react";
import { Global, css } from "@emotion/react";
import "bootstrap/dist/css/bootstrap.min.css";

const globalStyles = css`
  .ReactModal__Overlay {
    z-index: 9999999;
  }

  body {
    width: 100%;
    height: 100%;
    color: #185226;
    background-color: #fff;
    font-family: Arial Narrow, Arial, sans-serif;
    font-size: 22px;
    line-height: 26px;
  }
  html {
    width: 100%;
    height: 100%;
  }
  h1 {
    text-transform: uppercase;
  }
  img {
    display: block;
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
  [class^="col-"] {
    padding: 10px 0;
  }
  a:focus,
  a:active,
  a:visited {
    outline: 0;
    border: none;
  }
  #page-top {
    position: absolute;
    top: -20px;
    left: 0;
  }
  .navbar-fixed-top {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
  }
  .navbar-default {
    background-color: #fff;
    border-color: #fff;
    border: none;
  }
  li.active a {
    background-color: #fff !important;
  }
  @media (min-width: 767px) {
    .navbar {
      padding: 0 0 20px 0 !important;
    }
  }
  .navbar-brand {
    width: 100%;
    height: 100% !important;
    float: none !important;
    display: block;
    padding: 0;
  }
  .navbar-brand img {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40% !important;
  }
  @media (max-width: 768px) {
    .navbar-brand img {
      width: 60% !important;
    }
  }
  .logo {
    transition: all 0.3s;
  }
  .logo img {
    max-height: 50px;
  }
  @media (max-width: 576px) {
    .logo .is-scrolled {
      margin-top: 10px;
    }
  }
  .shrink {
    transform: translateY(-160px);
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06),
      0 4px 5px 0 rgba(0, 0, 0, 0.06), 0 1px 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06),
      0 4px 5px 0 rgba(0, 0, 0, 0.06), 0 1px 10px 0 rgba(0, 0, 0, 0.08);
  }
  @media (max-width: 576px) {
    .shrink {
      transform: translateY(-90px) !important;
    }
  }
  @media (min-width: 768px) {
    .shrink {
      transform: translateY(-150px);
    }
  }
  @media (max-width: 1024px) {
    .shrink {
      transform: translateY(-130px);
    }
  }
  li.is-scrolled:nth-child(2),
  li.is-scrolled:nth-child(3),
  li.is-scrolled:nth-child(4) {
    margin-top: 15px;
  }
  @media (max-width: 576px) {
    li.is-scrolled:nth-child(2),
    li.is-scrolled:nth-child(3),
    li.is-scrolled:nth-child(4) {
      width: 27% !important;
      margin-top: 0px;
    }
  }
  .is-scrolled {
    width: 25% !important;
  }
  @media (max-width: 576px) {
    .is-scrolled {
      width: 10% !important;
    }
  }
  li.is-scrolled a {
    padding: 10px 15px 10px 0;
  }
  @media (max-width: 768px) {
    li.is-scrolled a {
      padding: 20px 0 10px 0;
    }
  }
  @media (max-width: 576px) {
    li.is-scrolled a {
      padding-top: 0 !important;
    }
  }
  ul.nav {
    width: 80%;
    margin: 0 auto;
    float: none;
  }
  @media (max-width: 576px) {
    ul.nav {
      width: 100%;
    }
  }
  ul.nav img {
    margin: 0 !important;
    width: 100%;
  }
  @media (max-width: 576px) {
    ul.nav img {
      margin: 0 auto !important;
    }
  }
  ul.nav li {
    position: relative;
    width: 33.3333333%;
    text-align: center;
    display: inline-block;
  }
  ul.nav li a {
    cursor: pointer;
  }
  @media (max-width: 576px) {
    ul.nav li {
      width: 31%;
    }
  }
  ul.nav li a {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 768px) {
    ul.nav li a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media (max-width: 576px) {
    ul.nav li a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  ul.nav li:nth-child(2):before {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background-color: #185226;
    right: 5%;
    top: 45%;
    transform: translate(-5%, -45%);
  }
  @media (max-width: 768px) {
    ul.nav li:nth-child(2):before {
      display: none;
    }
  }
  ul.nav li:nth-child(3):before {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background-color: #185226;
    right: -3%;
    top: 45%;
    transform: translate(3%, -45%);
  }
  @media (max-width: 768px) {
    ul.nav li:nth-child(3):before {
      display: none;
    }
  }
  .swiper {
    margin-top: 290px;
  }
  .swiper .item img {
    display: block;
    width: 100%;
    height: auto;
  }
  @media (max-width: 576px) {
    .swiper {
      margin-top: 180px;
    }
  }
  .service-section {
    padding-top: 60px;
    text-align: center;
  }
  @media (max-width: 576px) {
    .service-section {
      padding-top: 0px;
    }
  }
  .service-section .service-text {
    padding-top: 30px;
    padding-right: 20px;
    text-align: left;
  }
  @media (max-width: 576px) {
    .service-section .row {
      margin-top: 10px;
    }
  }
  .service-section .service-h {
    display: inline-block;
  }

  .service-section .bierwagen-img {
    margin-right: auto;
    margin: 0;
    width: 95%;
    margin-top: 10px;
  }

  @media (max-width: 576px) {
    .service-section .bierwagen-img {
      display: block;
      width: 80%;
      height: auto;
      margin: 0 auto;
    }
  }
  .service-section .service-h img {
    width: 100%;
  }
  .service-section .lkw-img {
    width: 80% !important;
  }
  @media (max-width: 576px) {
    .service-section .lkw-img {
      width: 100% !important;
    }
  }
  @media (max-width: 768px) {
    .service-section .service-text {
      padding: 0 40px 30px 40px;
      font-size: 20px;
      text-align: left;
    }
  }
  .sortiment-section {
    text-align: center;
  }
  @media (max-width: 576px) {
    .sortiment-section {
      padding-top: 0px;
    }
  }
  .sortiment-section .sortiment-text {
    text-align: left;
  }
  .sortiment-section .row {
    margin-top: 30px;
  }
  .sortiment-section .theke-img {
    width: 60%;
    max-width: 400px;
  }
  .sortiment-section .tische-img {
    width: 80% !important;
  }
  @media (max-width: 576px) {
    .sortiment-section .tische-img {
      width: 100% !important;
    }
  }
  .sortiment-section .sortiment-h {
    display: inline-block;
  }
  .sortiment-section .sortiment-h img {
    width: 100%;
  }
  @media (max-width: 768px) {
    .sortiment-section .theke-img {
      margin-top: 0;
    }
    .sortiment-section .sortiment-text {
      padding: 30px 40px;
      text-align: left;
      font-size: 20px;
    }
  }
  @media (max-width: 576px) {
    .sortiment-section .theke-img {
      margin-top: 0px !important;
      max-height: 400px;
      width: 40%;
    }
  }
  .contact-section {
    padding-top: 30px;
    text-align: center;
    margin-bottom: 20px;
  }
  @media (max-width: 576px) {
    .contact-section {
      padding-top: 0px;
    }
  }
  .contact-section img {
    max-width: 700px;
    width: 100%;
  }
  .contact-section .email-overlay {
    position: absolute;
    display: inline-block;
    width: 30%;
    height: 40px;
    bottom: 31%;
    left: 50%;
    transform: translate(-50%, -31%);
  }
  @media (max-width: 768px) {
    .contact-section .email-overlay {
      width: 50%;
    }
  }
  .contact-section .kontakt-h {
    display: inline-block;
    margin-bottom: 10px;
  }
  .contact-section .kontakt-h img {
    width: 100%;
  }
  .footer-section {
    padding: 10px;
    width: 100%;
    background-color: #185226;
    color: #fff;
  }
  .footer-section a {
    color: #fff;
    text-decoration: none;
  }
  #impressumsModal {
    color: #185226;
    font-size: 16px;
    line-height: 18px;
  }
  #impressumsModal a {
    color: #185226;
  }
  #impressumsModal button {
    border-radius: 0;
    color: #185226;
  }
  @media (min-width: 768px) {
    #impressumsModal .modal-dialog {
      width: 60%;
      margin: 30px auto;
    }
  }
`;

export const Layout = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />
      {children}
    </>
  );
};
