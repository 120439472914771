import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";

SwiperCore.use([Autoplay]);

export const SwiperComponent = () => {
  return (
    <section id="banner" className="banner-section">
      <Swiper
        className="swiper"
        autoplay={{ delay: 5000 }}
        spaceBetween={0}
        slidesPerView={1}
      >
        <SwiperSlide className="item">
          <img src="owl-items/item-1.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-2.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-3.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-4.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-5.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-6.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-7.png" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <img src="owl-items/item-8.png" />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
